import React, {useCallback, useMemo, useState} from "react";
import {Dropdown, MenuProps} from "antd";
import type {ItemType} from "antd/es/menu/interface";
import classNames from "classnames/bind";
import Swal from "sweetalert2";

import Button from "~/components/button";
import {CustomLabelTable} from "~/components/custom-label-table/custom-label-table";
import {PageTitle} from "~/components/page-title";
import {useDrive} from "~/data/drive/use-drive";
import {Category, RawCategory} from "~/data/drive/use-drive.interface";
import {useUser} from "~/data/user";
import {DriveBoardModal} from "~/pages/drive-board/modal/drive-board.modal";
import {DriveBoardAddModal} from "~/pages/drive-board/modal/drive-board-add.modal";

import styles from "./drive-board.page.module.scss";

const cx = classNames.bind(styles);

const DriveBoardPage = () => {
    const perPage = 9999;
    const [page, setPage] = useState<number>(1);
    const [modal, setModal] = useState<RawCategory | false>({
        categoryId: 0,
        id: 1,
        parent: "#",
        companyId: 13,
        text: "롯데마트",
        state: "ACTIVE",
        createUser: 0,
        updateUser: 0,
        targetType: "ALL",
        targetId: [],
    });
    const [addModal, setAddModal] = useState<boolean>(false);
    const [filter, setFilter] = useState<{fileName: string; category: string}>({
        fileName: "",
        category: "",
    });
    const [selectedCategory, setSelectedCategory] = useState<number>(1);
    const [parentCategory, setParentCategory] = useState<RawCategory | undefined>({
        categoryId: 0,
        id: 1,
        parent: "#",
        companyId: 13,
        text: "롯데마트",
        state: "ACTIVE",
        createUser: 0,
        updateUser: 0,
        targetType: "ALL",
        targetId: [],
    });

    const {formattedCategory, rawCategories, refresh, changeParentFolderForFolder} = useDrive();
    const {user} = useUser();

    const handleReset = useCallback(() => {
        setFilter({
            fileName: "",
            category: "",
        });
    }, []);

    const filteredCategory = useMemo(() => {
        const arr = rawCategories.filter((row) => Number(row.parent) === selectedCategory);
        arr.sort((a, b) => a.text.localeCompare(b.text));
        return arr;
    }, [rawCategories, selectedCategory]);

    const itemMoveList: (target: number) => MenuProps["items"] = useCallback(
        (target: number) => {
            const formatted = (raw: Category[]): MenuProps["items"] => {
                return raw
                    .filter((item) => item.id !== target)
                    .map((row: Category) => {
                        const rst: ItemType =
                            row.children && row.children.length > 0
                                ? {
                                      key: row.id + "g",
                                      label: row.text,
                                      children: [
                                          {
                                              key: Number(row.id),
                                              label: "여기로 이동",
                                              onClick: (e: any) => {
                                                  e.domEvent.preventDefault();
                                                  e.domEvent.stopPropagation();
                                                  Swal.fire({
                                                      text: "폴더를 이동하시겠습니까?",
                                                      showCancelButton: true,
                                                      confirmButtonText: "확인",
                                                      cancelButtonText: "취소",
                                                  }).then((info) => {
                                                      if (info.isConfirmed) {
                                                          changeParentFolderForFolder(target, row.id)
                                                              .submit()
                                                              .then((res) => {
                                                                  refresh();
                                                                  Swal.fire({
                                                                      text: "이동되었습니다.",
                                                                      confirmButtonText: "확인",
                                                                  });
                                                              });
                                                      }
                                                  });
                                              },
                                          },
                                          ...(formatted(row.children) ?? []),
                                      ],
                                  }
                                : {
                                      key: Number(row.id),
                                      label: row.text,
                                      onClick: (e) => {
                                          e.domEvent.preventDefault();
                                          e.domEvent.stopPropagation();
                                          Swal.fire({
                                              text: "폴더를 이동하시겠습니까?",
                                              showCancelButton: true,
                                              confirmButtonText: "확인",
                                              cancelButtonText: "취소",
                                          }).then((info) => {
                                              if (info.isConfirmed) {
                                                  changeParentFolderForFolder(target, row.id)
                                                      .submit()
                                                      .then((res) => {
                                                          refresh();
                                                          Swal.fire({
                                                              text: "이동되었습니다.",
                                                              confirmButtonText: "확인",
                                                          });
                                                      });
                                              }
                                          });
                                      },
                                  };
                        return rst;
                    });
            };

            return formatted(formattedCategory);
        },
        [formattedCategory],
    );

    return (
        <div>
            {/*{modal && <DriveBoardModal targetData={modal} close={() => setModal(false)} refresh={refresh} />}*/}
            {addModal && modal && (
                <DriveBoardAddModal
                    parent={modal.id}
                    isShow={addModal}
                    close={() => setAddModal(false)}
                    refresh={refresh}
                />
            )}
            <PageTitle>자료실</PageTitle>
            {/*<FilterArea>*/}
            {/*    <div className={cx("filter-inner")}>*/}
            {/*        <CnInput label={"파일명"} placeholder={"파일명 검색"} />*/}
            {/*        <div className={cx("button-container")}>*/}
            {/*            <Button label={"조회"} color={"primary"} onClick={() => refresh()} />*/}
            {/*            <Button label={"검색조건 초기화"} onClick={handleReset} />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</FilterArea>*/}
            <div className={cx("table-container")}>
                <CustomLabelTable
                    label={
                        <div className={cx("table-label")}>
                            <span>
                                카테고리
                                {/*{rawCategories.length} 건 ({page}/{Math.ceil(rawCategories.length / perPage)} 페이지)*/}
                            </span>
                            <div>
                                {selectedCategory !== 1 && (
                                    <Button
                                        label={"상위 폴더"}
                                        color={"primary"}
                                        onClick={() => {
                                            setSelectedCategory(parentCategory ? parentCategory.id : 1);
                                            setModal(
                                                parentCategory ?? {
                                                    categoryId: 0,
                                                    id: 1,
                                                    parent: "#",
                                                    companyId: 13,
                                                    text: "롯데마트",
                                                    state: "ACTIVE",
                                                    createUser: 0,
                                                    updateUser: 0,
                                                    targetType: "ALL",
                                                    targetId: [],
                                                },
                                            );
                                            setParentCategory((p) =>
                                                rawCategories.find((item) => item.id === Number(p ? p.parent : 1)),
                                            );
                                        }}
                                    />
                                )}
                                {user?.division === "MEMBER" && (
                                    <Button label={"카테고리 추가"} onClick={() => setAddModal(true)} />
                                )}
                            </div>
                        </div>
                    }
                    page={page}
                    perPage={perPage}
                    noPagination
                    onPageChange={setPage}
                    totalCount={filteredCategory.length}
                    dataSource={filteredCategory}
                    pagination={false}
                    rowKey={"id"}
                    onRow={(record) => ({
                        onClick: () => {
                            setModal(record);
                            setSelectedCategory(Number(record.id));
                            setParentCategory(rawCategories.find((item) => item.id === Number(record.parent)));
                        },
                        style: {
                            cursor: "pointer",
                        },
                    })}
                    columns={[
                        {
                            title: "No",
                            align: "center",
                            render: (_, __, index) => index + 1,
                        },
                        {
                            title: "카테고리",
                            dataIndex: "text",
                            align: "center",
                        },
                        {
                            title: "이동",
                            align: "center",
                            hidden: user?.division === "MANAGER",
                            render: (value, record, index) => {
                                return (
                                    <Dropdown menu={{items: itemMoveList(record.id)}} trigger={["click"]}>
                                        <Button
                                            label="이동"
                                            color={"primary"}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        />
                                    </Dropdown>
                                );
                            },
                        },
                    ]}
                />
                <div className={cx("file-list-container")}>
                    {modal ? (
                        <DriveBoardModal close={() => setModal(false)} refresh={refresh} targetData={modal} />
                    ) : (
                        "카테고리를 선택하세요"
                    )}
                </div>
            </div>
        </div>
    );
};

export {DriveBoardPage};
