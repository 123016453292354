import {memo, useCallback, useEffect, useState} from "react";
import {faCircle, faCirclePlus, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DatePicker} from "antd";
import classNames from "classnames/bind";
import dayjs, {Dayjs} from "dayjs";
import Swal from "sweetalert2";

import {useCalendarList, useCalendarUpsert} from "~/data/dashboard/calendar/use-calendar-list";
import {CalendarDTOListType} from "~/data/dashboard/calendar/use-calendar-list.interface";
import {DashboardCalendarDaysProps} from "~/pages/dashboard/calendar/dashboard-calendar.interface";
import {DashboardCalendarModal} from "~/pages/dashboard/calendar/modal/dashboard-calendar.modal";
import {useDayInMonth} from "~/utills/use-day-in-month";

import styles from "./dashboard-calendar.module.scss";

const cx = classNames.bind(styles);

// eslint-disable-next-line react/display-name
const Days = memo(
    ({day, outOfMonth, date, setDate, scheduleList, setSelectedDay, selectedDay}: DashboardCalendarDaysProps) => {
        const [schedules, setSchedules] = useState<CalendarDTOListType[]>([]);

        const handleClick = useCallback(() => {
            if (selectedDay?.format("YYYYMMD") === date.format("YYYYMM") + day) setSelectedDay(null);
            else setSelectedDay(dayjs(date.format("YYYYMM") + day));
        }, [date, day, selectedDay]);
        const handleClickOutOfMonth = useCallback(() => {
            if (day > 15) setDate((p) => p.subtract(1, "month"));
            else setDate((p) => p.add(1, "month"));
        }, [date]);

        useEffect(() => {
            if (outOfMonth) setSchedules([]);
            else setSchedules(scheduleList.filter((item) => item.start === date.format("YYYYMM") + day));
        }, [scheduleList]);

        return (
            <div
                className={cx(
                    "day",
                    {"out-of-month": outOfMonth},
                    {today: !outOfMonth && date.format("YYYYMM") + day === dayjs().format("YYYYMMD")},
                    {selected: selectedDay?.format("YYYYMMD") === date.format("YYYYMM") + day && !outOfMonth},
                )}
                onClick={outOfMonth ? handleClickOutOfMonth : handleClick}
            >
                <span>{day}</span>
                <p>
                    {schedules.slice(0, 3).map((item, index) => (
                        <FontAwesomeIcon icon={faCircle} className={cx("icon")} key={index} />
                    ))}
                </p>
            </div>
        );
    },
);

const DashboardCalendar = () => {
    const [elements, setElements] = useState<JSX.Element[]>([]);
    const [modal, setModal] = useState<boolean>(false);
    const [modalData, setModalData] = useState<CalendarDTOListType | null>(null);
    const [date, setDate] = useState<Dayjs>(dayjs());
    const [selectedDay, setSelectedDay] = useState<Dayjs | null>(null);

    const {days, prevDow, nextDow} = useDayInMonth({year: date.year(), month: date.month() + 1});

    const {submitScheduleList, scheduleList, loading} = useCalendarList({
        year: date.year(),
        month: date.month() + 1,
        day: null,
    });
    const {submitScheduleList: submitSelectedDayScheduleList, scheduleList: selectedScheduleList} = useCalendarList({
        year: date.year(),
        month: date.month() + 1,
        day: selectedDay?.date() || null,
    });
    const {deleteList} = useCalendarUpsert();

    const handleDelete = (id: number) => {
        Swal.fire({
            text: "삭제하시겠습니까?",
            showCancelButton: true,
            confirmButtonText: "확인",
            cancelButtonText: "취소",
        })
            .then((info) => {
                if (info.isConfirmed) {
                    deleteList(id);
                }
            })
            .finally(() => {
                setTimeout(() => {
                    submitScheduleList();
                    submitSelectedDayScheduleList();
                }, 500);
            });
    };

    useEffect(() => {
        const prevTemp = [];
        for (let i = 0; i < prevDow; i++) {
            const prevDay = date.subtract(1, "month").endOf("month").date() - (prevDow - (i + 1));
            prevTemp.push(prevDay);
        }
        const nextTemp = [];
        let nextDay = 1;
        for (let i = 6; i > nextDow; i--) {
            nextTemp.push(nextDay);
            nextDay++;
        }
        setElements(
            [...prevTemp, ...days, ...nextTemp].map((day, index) => (
                <Days
                    key={index}
                    day={day}
                    outOfMonth={(index < 10 && day > 15) || (index > 25 && day < 10)}
                    date={date}
                    setDate={setDate}
                    scheduleList={scheduleList}
                    setSelectedDay={setSelectedDay}
                    selectedDay={selectedDay}
                />
            )),
        );
    }, [days, prevDow, nextDow, scheduleList, selectedDay]);

    useEffect(() => {
        submitScheduleList();
    }, [date]);
    useEffect(() => {
        submitSelectedDayScheduleList();
    }, [date, selectedDay]);
    useEffect(() => {
        if (!modal) setModalData(null);
    }, [modal]);

    return (
        <div className={cx("wrapper")}>
            {modal && (
                <DashboardCalendarModal
                    isShow={modal}
                    close={() => setModal(false)}
                    refresh={async () => {
                        await submitScheduleList();
                        await submitSelectedDayScheduleList();
                    }}
                    date={date}
                    selectedDay={selectedDay}
                    data={modalData}
                />
            )}
            <div className={cx("calendar-header")}>
                <DatePicker picker={"month"} value={date} onChange={setDate} allowClear={false} />
                <FontAwesomeIcon icon={faCirclePlus} className={cx("icon")} onClick={() => setModal(true)} />
            </div>
            <div className={cx("calendar-body")}>
                <div className={cx("dow-container")}>
                    <span className={cx("sun")}>일</span>
                    <span>월</span>
                    <span>화</span>
                    <span>수</span>
                    <span>목</span>
                    <span>금</span>
                    <span className={cx("sat")}>토</span>
                </div>
                <div className={cx("day-container")}>{elements}</div>
            </div>
            <div className={cx("calendar-footer")}>
                <ul className={cx("schedule-list")}>
                    {selectedDay && selectedScheduleList.length === 0 && (
                        <li className={cx("schedule-item")}>항목이 없습니다</li>
                    )}
                    {!selectedDay && scheduleList.length === 0 && (
                        <li className={cx("schedule-item")}>항목이 없습니다</li>
                    )}
                    {(selectedDay ? selectedScheduleList : scheduleList).map((item, index) => (
                        <li
                            className={cx("schedule-item")}
                            key={index}
                            onClick={() => {
                                setModalData(item);
                                setModal(true);
                            }}
                        >
                            <FontAwesomeIcon icon={faCircle} className={cx("icon")} />
                            <div className={cx("info")}>
                                <p>
                                    <span>{dayjs(item.start).format("D")}</span>
                                    <span className={cx("title")}>{item.title}</span>
                                </p>
                                <p>
                                    <span>{dayjs(item.start).format("dd")}</span>
                                    <span>{item.startTime.slice(0, 5)}</span>
                                </p>
                            </div>
                            {/*<FontAwesomeIcon icon={faSquarePen} className={cx("update-icon")} />*/}
                            <FontAwesomeIcon
                                icon={faTrashCan}
                                className={cx("trash-icon")}
                                onClick={() => handleDelete(item.calendarId)}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export {DashboardCalendar};
