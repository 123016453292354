import {useCallback, useMemo, useState} from "react";
import {UploadFile} from "antd";
import Swal from "sweetalert2";

import {httpRequest} from "~/fetch/common/http-request";

import {
    MergeRegisterCheckDRM,
    MergeRegisterCheckDRMResponse,
    MergeRegisterFileUploadResponse,
    MergeRegisterRequest,
    MergeRegisterTargetList,
    MergeRegistration,
    SectorResponse,
    SpecificResponse,
    TrainerResponse,
} from "./register.interface";

// 등록
const useMergeRegistration = (config: MergeRegistration): [() => Promise<void>, boolean] => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const getRefinedTargetList = useCallback(() => {
        switch (config.targetType) {
            case "SECTOR":
                return config.targetDTOList.map((data) => ({
                    sectorId: Number(data.toString().split("_")[1]),
                    targetType: config.targetType,
                }));
            case "TRAINER":
                return config.targetDTOList.map((data) => ({
                    trainerSectorId: Number(data.toString().split("_")[1]),
                    targetType: config.targetType,
                }));
            case "SPECIFIC":
                return config.targetDTOList.map((data) => ({
                    buildingId: data,
                    targetType: config.targetType,
                }));
            default:
                return [];
        }
    }, [config.targetType, config.targetDTOList]);

    const request = useMemo(() => {
        return (fileInfo: MergeRegisterFileUploadResponse) => {
            const {
                year,
                month,
                dateRange,
                mergeType,
                targetType,
                title,
                content,
                managerName,
                questionDTOList,
                important,
                fileFreezePaneCounts,
                fileSheetNames,
            } = config;

            return httpRequest<MergeRegisterRequest, null>({
                method: "POST",
                url: "/lotte/merge/member/reg",
                data: {
                    year: Number(year),
                    month,
                    startDate: dateRange![0].format("YYYY-MM-DD"),
                    endDate: dateRange![1].format("YYYY-MM-DD"),
                    managerNm: managerName,
                    title: title,
                    mergeType: mergeType,
                    important: important,
                    targetType: targetType,
                    noticeMergeTargetDTOList:
                        targetType === "ALL"
                            ? [
                                  {
                                      targetType: "ALL",
                                  },
                              ]
                            : getRefinedTargetList(),
                    content: content,
                    noticeMergeQuestionDTOList: questionDTOList.map((data) => ({question: data.value})),
                    addFileDTOList: fileInfo.addFileDTOList,
                    fileFreezePaneCounts,
                    fileSheetNames,
                    formFileOriginName: fileInfo.excelFileInfo.fileOriginName,
                    formFileStorePath: fileInfo.excelFileInfo.fileStorePath,
                },
            });
        };
    }, [config]);

    const fileUpload = useMemo(() => {
        const formData = new FormData();
        const toFile = (uploadFile: UploadFile): File => {
            return (uploadFile.originFileObj as File) || null;
        };
        const convertedFiles = config.files.sub.map((data) => toFile(data));
        convertedFiles.forEach((file) => {
            formData.append(`addFiles`, file);
        });

        if (config.files.main) {
            formData.append("excelFile", config.files.main);
        }

        return httpRequest<FormData, MergeRegisterFileUploadResponse>({
            method: "POST",
            url: "/lotte/merge/member/fileUpload",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        });
    }, [config.files]);

    const submit = async () => {
        try {
            setIsLoading(true);
            const fileInfo = await fileUpload.submit();
            await request(fileInfo.data).submit();
        } catch (error) {
            console.log(error);
            setError(error);
            Swal.fire({
                text: "에러가 발생하였습니다 다시 시도해주세요",
                confirmButtonText: "확인",
            });
        } finally {
            setIsLoading(false);
        }
    };

    return [submit, isLoading];
};

// 대상 리스트
const useMergeRegisterTargetList = (): [() => Promise<void>, MergeRegisterTargetList | null, boolean] => {
    const [response, setResponse] = useState<MergeRegisterTargetList | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const reqSector = useMemo(() => {
        return httpRequest<null, Array<SectorResponse>>({
            method: "GET",
            url: "/sector/list",
        });
    }, []);
    const reqSpecific = useMemo(() => {
        return httpRequest<null, Array<SpecificResponse>>({
            method: "GET",
            url: "/sector/manager",
        });
    }, []);
    const reqTrainer = useMemo(() => {
        return httpRequest<null, Array<TrainerResponse>>({
            method: "GET",
            url: "/common/trainerSector",
        });
    }, []);

    const submit = async () => {
        try {
            setIsLoading(true);
            const resSector = await reqSector.submit();
            const resSpecific = await reqSpecific.submit();
            const resTrainer = await reqTrainer.submit();

            setResponse({
                sector: resSector.data.map((data) => ({id: data.sectorId, name: data.sectorName})),
                specific: resSpecific.data.map((data) => ({id: data.buildingId, name: data.buildingName})),
                trainer: resTrainer.data.map((data) => ({id: data.trainerSectorId, name: data.trainerSectorName})),
            });
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    return [submit, response, isLoading];
};

// 첨부양식 DRM 검사
const useMergeRegisterCheckDRM = (
    config: MergeRegisterCheckDRM,
): [
    (uploadFile: File | null) => Promise<MergeRegisterCheckDRMResponse | null>,
    MergeRegisterCheckDRMResponse | null,
    boolean,
    any,
] => {
    const [response, setResponse] = useState<MergeRegisterCheckDRMResponse | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        return (uploadFile: File | null) => {
            return httpRequest<{uploadFile: File | null}, MergeRegisterCheckDRMResponse>({
                method: "POST",
                url: "/lotte/merge/member/excelCheckAndExtract",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                data: {uploadFile},
            });
        };
    }, [config]);

    const submit = async (uploadFile: File | null) => {
        try {
            setIsLoading(true);

            const res = await request(uploadFile).submit();
            setResponse(res.data);
            return res.data;
        } catch (error) {
            setError(error);
            console.log(error);
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    return [submit, response, isLoading, error];
};

export {useMergeRegisterCheckDRM, useMergeRegisterTargetList, useMergeRegistration};
