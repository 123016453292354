import React, {useEffect, useState} from "react";
import {Input, message, Radio} from "antd";
import classNames from "classnames/bind";
import Swal from "sweetalert2";

import Button from "~/components/button";
import {MergeTargetList} from "~/components/merge/merge-target-list";
import {CenterModal} from "~/components/modal/center-modal";
import {TargetType} from "~/data/merge/merge.interface";
import {useMergeRegisterTargetList} from "~/data/merge/register";
import {httpRequest} from "~/fetch/common/http-request";
import {DriveBoardAddModalProps} from "~/pages/drive-board/modal/drive-board-add.modal.interface";

import styles from "~/pages/drive-board/modal/drive-board.modal.module.scss";

const cx = classNames.bind(styles);

const DriveBoardAddModal = ({parent, close, refresh, isUpdate, isShow, updateClose}: DriveBoardAddModalProps) => {
    const [accessible, setAccessible] = useState<{targetType: TargetType; targetIdList: (string | number)[]}>({
        targetType: "ALL",
        targetIdList: [],
    });
    const [title, setTitle] = useState<string>("");

    const [submitTargetList, targetList] = useMergeRegisterTargetList();
    const [searchSpecific, setSearchSpecific] = useState<string>("");

    const handleConfirm = async () => {
        try {
            await httpRequest<
                {
                    parent: string;
                    text: string;
                    targetType: string;
                    targetIdList: (string | number)[] | null;
                },
                any
            >({
                method: "POST",
                url: "/fileBoard/setFileCategory",
                data: {
                    parent: String(parent),
                    text: title,
                    targetType: accessible.targetType,
                    targetIdList:
                        accessible.targetType === "ALL"
                            ? null
                            : accessible.targetType === "SPECIFIC"
                              ? accessible.targetIdList
                              : accessible.targetIdList.map((item) => Number(item.toString().split("_")[1])),
                },
            })
                .submit()
                .then((res) => {
                    if (res.data.responseData === "SUCCESS") message.success("저장되었습니다.");
                    else
                        Swal.fire({
                            text: "오류가 발생하였습니다. 다시 시도해주세요",
                            confirmButtonText: "확인",
                        });
                })
                .catch((e) => console.log(e));
        } catch (e) {
            Swal.fire({
                text: "오류가 발생하였습니다. 다시 시도해주세요",
                confirmButtonText: "확인",
            });
        } finally {
            refresh();
            close();
        }
    };

    const handleUpdate = async () => {
        try {
            await httpRequest<
                {
                    id: number;
                    text: string;
                    targetType: string;
                    targetIdList: (string | number)[] | null;
                },
                any
            >({
                method: "PUT",
                url: "/fileBoard/setModifyFileCategory",
                data: {
                    id: isUpdate!.id,
                    text: title,
                    targetType: accessible.targetType,
                    targetIdList:
                        accessible.targetType === "ALL"
                            ? null
                            : accessible.targetType === "SPECIFIC"
                              ? accessible.targetIdList
                              : accessible.targetIdList.map((item) => Number(item.toString().split("_")[1])),
                },
            })
                .submit()
                .then((res) => {
                    message.success("저장되었습니다.");
                })
                .catch((e) => console.log(e));
        } catch (e) {
            console.log(e);
        } finally {
            updateClose!();
            refresh();
            close();
        }
    };

    useEffect(() => {
        submitTargetList();
    }, []);
    useEffect(() => {
        if (isUpdate) {
            setAccessible({
                targetType: isUpdate.targetType as TargetType,
                targetIdList: isUpdate.targetDTOList.map((item) =>
                    isUpdate.targetType === "SECTOR" || isUpdate.targetType === "TRAINER"
                        ? `${isUpdate.targetType}_${item}`
                        : item,
                ),
            });
            setTitle(isUpdate.title);
        }
    }, [isUpdate]);

    return (
        <CenterModal
            close={close}
            isShow={isShow}
            extraButtons={[
                <Button
                    label={isUpdate ? "수정하기" : "추가하기"}
                    onClick={isUpdate ? handleUpdate : handleConfirm}
                    key={"confirm"}
                />,
            ]}
            width={800}
            closeIcon={false}
            title={isUpdate ? "카테고리 수정" : "카테고리 추가"}
            zIndex={13000}
        >
            <>
                <p style={{marginTop: 12}}>카테고리 명</p>{" "}
                <Input placeholder={"카테고리 명 입력"} value={title} onChange={(e) => setTitle(e.target.value)} />
                <p style={{marginTop: 12}}>접근권한 설정</p>
                <div className={cx("accessible", "accessible-add")}>
                    <Radio.Group
                        className={cx("radio-group")}
                        value={accessible.targetType}
                        onChange={(e) => {
                            if (e.target.value === "ALL")
                                setAccessible({
                                    targetType: e.target.value,
                                    targetIdList: [0],
                                });
                            else setAccessible({targetIdList: [], targetType: e.target.value});
                        }}
                        disabled={false}
                    >
                        <Radio className={cx("radio")} value={"ALL"}>
                            전체
                        </Radio>
                        <Radio className={cx("radio")} value={"SECTOR"}>
                            특정부문
                        </Radio>
                        <Radio className={cx("radio")} value={"TRAINER"}>
                            특정 트레이너
                        </Radio>
                        <Radio className={cx("radio")} value={"SPECIFIC"}>
                            특정지점
                        </Radio>
                    </Radio.Group>
                    <div className={cx("accessible-search-container")} style={{marginBottom: 12}}>
                        <span className={cx("count")}>
                            {accessible.targetIdList ? accessible.targetIdList.length : 0}개 선택됨
                        </span>
                        <Input
                            className={cx("input")}
                            placeholder="지점명 검색"
                            value={searchSpecific}
                            disabled={accessible.targetType !== "SPECIFIC"}
                            onChange={(e) => setSearchSpecific(e.target.value)}
                        />
                    </div>
                </div>
                <MergeTargetList
                    selectedTargetList={accessible.targetIdList}
                    targetType={accessible.targetType}
                    targetList={targetList}
                    searchText={searchSpecific}
                    onChange={(e) => setAccessible((p) => ({...p, targetIdList: e}))}
                    noMargin
                />
            </>
        </CenterModal>
    );
};

export {DriveBoardAddModal};
