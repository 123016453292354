import React, {useCallback, useEffect, useState} from "react";
import {Dropdown, MenuProps, message, Table} from "antd";
import type {ItemType} from "antd/es/menu/interface";
import classNames from "classnames/bind";
import Swal from "sweetalert2";

import Button from "~/components/button";
import {useDrive} from "~/data/drive/use-drive";
import {Category} from "~/data/drive/use-drive.interface";
import {useFiles} from "~/data/drive/use-files";
import {AddFileRequest} from "~/data/drive/use-files.interface";
import {TargetType} from "~/data/merge/merge.interface";
import {useMergeRegisterTargetList} from "~/data/merge/register";
import {useUser} from "~/data/user";
import {httpRequest} from "~/fetch/common/http-request";
import {AddNewFileModal} from "~/pages/drive/add-new-file.modal";
import {FileDetailModal} from "~/pages/drive/file-detail.modal";
import {DriveBoardFileInputType, DriveBoardModalProps} from "~/pages/drive-board/modal/drive-board.modal.interface";
import {DriveBoardAddModal} from "~/pages/drive-board/modal/drive-board-add.modal";

import styles from "./drive-board.modal.module.scss";

const cx = classNames.bind(styles);

const DriveBoardModal = ({close, targetData, refresh}: DriveBoardModalProps) => {
    const [accessible, setAccessible] = useState<{targetType: TargetType; targetDTOList: number[]}>({
        targetType: "ALL",
        targetDTOList: [],
    });
    const [searchSpecific, setSearchSpecific] = useState<string>("");
    const [detailModal, setDetailModal] = useState<number | false>(false);
    const [isAdd, setIsAdd] = useState<boolean>(false);
    // const [addLoading, setAddLoading] = useState<boolean>(false);
    const [isAddInputs, setIsAddInputs] = useState<DriveBoardFileInputType>({
        title: "",
        contents: "",
        lockYn: "N",
        fileBoardDataDTOList: [],
    });
    const [updateModal, setUpdateModal] = useState<boolean>(false);

    const [submitTargetList, targetList] = useMergeRegisterTargetList();
    const {fileList, addFile, submit, loading} = useFiles(targetData.id);
    const {user} = useUser();

    const handleResetAddInputs = useCallback(() => {
        setIsAdd(false);
        setIsAddInputs({
            title: "",
            contents: "",
            lockYn: "N",
            fileBoardDataDTOList: [],
        });
    }, []);

    const handleSave = (data: Omit<AddFileRequest, "categoryId">) => {
        addFile({
            ...data,
            categoryId: Number(targetData.id),
        })
            .submit()
            .then(() => {
                submit({
                    allRecordCnt: 0,
                    categoryId: Number(targetData.id),
                    pageNo: 1,
                    recordPerPage: 50,
                    startRecordNo: 0,
                    includeSubfolders: false,
                });
                setIsAdd(false);
            })
            .catch(() => {
                Swal.fire({
                    title: "오류가 발생했습니다.",
                    text: "문제가 지속될 경우 관리자에 문의하세요.",
                    confirmButtonText: "확인",
                });
            });
    };
    const handleDelete = async () => {
        Swal.fire({
            title: "정말 삭제하시겠습니까?",
            text: "첨부된 모든 파일이 삭제됩니다",
            showCancelButton: true,
            confirmButtonText: "삭제하기",
            cancelButtonText: "취소하기",
        })
            .then((info) => {
                if (info.isConfirmed) {
                    const loading = message.loading("삭제중입니다...", 0);
                    httpRequest<{id: string | number}, any>({
                        method: "DELETE",
                        url: "fileBoard/setDeleteFileCategory",
                        params: {
                            id: targetData.id,
                        },
                    })
                        .submit()
                        .then(() => {
                            loading();
                            message.success("삭제되었습니다.");
                            refresh();
                            close();
                        })
                        .catch((e) =>
                            Swal.fire({
                                text: "오류가 발생하였습니다. 다시 시도해주세요",
                                confirmButtonText: "확인",
                            }),
                        );
                }
            })
            .finally(() => {
                close();
            });
    };

    useEffect(() => {
        submit({
            allRecordCnt: 0,
            categoryId: Number(targetData.id),
            pageNo: 1,
            recordPerPage: 50,
            startRecordNo: 0,
            includeSubfolders: false,
        });
        setAccessible({
            targetType: targetData.targetType as TargetType,
            targetDTOList: targetData.targetId ?? [],
        });
    }, [targetData]);
    useEffect(() => {
        submitTargetList();
    }, []);

    const {formattedCategory, rawCategories, changeParentFolderForFolder, changeParentFolderForFile} = useDrive();

    const itemMoveList: (target: number) => MenuProps["items"] = useCallback(
        (target: number) => {
            const formatted = (raw: Category[]): MenuProps["items"] => {
                return raw.map((row: Category) => {
                    const rst: ItemType =
                        row.children && row.children.length > 0
                            ? {
                                  key: row.id + "g",
                                  label: row.text,
                                  children: [
                                      {
                                          key: Number(row.id),
                                          label: "여기로 이동",
                                          onClick: (e) => {
                                              e.domEvent.preventDefault();
                                              e.domEvent.stopPropagation();
                                              Swal.fire({
                                                  text: "파일을 이동하시겠습니까?",
                                                  showCancelButton: true,
                                                  confirmButtonText: "확인",
                                                  cancelButtonText: "취소",
                                              }).then((info) => {
                                                  if (info.isConfirmed) {
                                                      changeParentFolderForFile(target, row.id)
                                                          .submit()
                                                          .then((res) => {
                                                              console.log(targetData);
                                                              submit({
                                                                  allRecordCnt: 0,
                                                                  categoryId: Number(targetData.id),
                                                                  pageNo: 1,
                                                                  recordPerPage: 50,
                                                                  startRecordNo: 0,
                                                                  includeSubfolders: false,
                                                              });
                                                              Swal.fire({
                                                                  text: "이동되었습니다.",
                                                                  confirmButtonText: "확인",
                                                              });
                                                          });
                                                  }
                                              });
                                          },
                                      },
                                      ...(formatted(row.children) ?? []),
                                  ],
                              }
                            : {
                                  key: Number(row.id),
                                  label: row.text,
                                  onClick: (e) => {
                                      e.domEvent.preventDefault();
                                      e.domEvent.stopPropagation();
                                      Swal.fire({
                                          text: "파일을 이동하시겠습니까?",
                                          showCancelButton: true,
                                          confirmButtonText: "확인",
                                          cancelButtonText: "취소",
                                      }).then((info) => {
                                          if (info.isConfirmed) {
                                              changeParentFolderForFile(target, row.id)
                                                  .submit()
                                                  .then((res) => {
                                                      submit({
                                                          allRecordCnt: 0,
                                                          categoryId: Number(targetData.id),
                                                          pageNo: 1,
                                                          recordPerPage: 50,
                                                          startRecordNo: 0,
                                                          includeSubfolders: false,
                                                      });
                                                      Swal.fire({
                                                          text: "이동되었습니다.",
                                                          confirmButtonText: "확인",
                                                      });
                                                  });
                                          }
                                      });
                                  },
                              };
                    return rst;
                });
            };

            return formatted(formattedCategory);
        },
        [formattedCategory, targetData],
    );

    return (
        <div className={cx("file-list-wrapper")}>
            <>
                {updateModal && (
                    <DriveBoardAddModal
                        parent={0}
                        close={() => setUpdateModal(false)}
                        isShow={updateModal}
                        refresh={refresh}
                        updateClose={() => close()}
                        isUpdate={{
                            id: targetData.id,
                            title: targetData.text,
                            targetType: targetData.targetType,
                            targetDTOList: targetData.targetId,
                        }}
                    />
                )}
                {detailModal && (
                    <FileDetailModal
                        fileBoardSeq={detailModal}
                        onClose={() => setDetailModal(false)}
                        refresh={() =>
                            submit({
                                allRecordCnt: 0,
                                categoryId: Number(targetData.id),
                                pageNo: 1,
                                recordPerPage: 50,
                                startRecordNo: 0,
                                includeSubfolders: false,
                            })
                        }
                    />
                )}
                {isAdd && (
                    <AddNewFileModal
                        onSave={handleSave}
                        onClose={() => {
                            setIsAdd(false);
                        }}
                        refresh={() =>
                            submit({
                                allRecordCnt: 0,
                                categoryId: Number(targetData.id),
                                pageNo: 1,
                                recordPerPage: 50,
                                startRecordNo: 0,
                                includeSubfolders: false,
                            })
                        }
                    />
                )}
                <div className={cx("label")}>
                    [{targetData.text}] 파일 리스트
                    <div className={cx("folder-button-container")}>
                        {user?.division === "MEMBER" && <Button label={"파일등록"} onClick={() => setIsAdd(true)} />}
                        {user?.division === "MEMBER" && (
                            <Button label={"카테고리 수정"} onClick={() => setUpdateModal(true)} />
                        )}
                        {user?.division === "MEMBER" && <Button label={"카테고리 삭제"} onClick={handleDelete} />}
                    </div>
                </div>
                <Table
                    className={cx("table")}
                    dataSource={fileList}
                    pagination={false}
                    rowKey={"fileBoardSeq"}
                    onRow={(record) => ({
                        onClick: () => setDetailModal(record.fileBoardSeq),
                        style: {
                            cursor: "pointer",
                        },
                    })}
                    columns={[
                        {
                            title: "No",
                            dataIndex: "fileBoardSeq",
                        },
                        {
                            title: "제목",
                            dataIndex: "title",
                            width: "50%",
                        },
                        {
                            title: "작성자",
                            dataIndex: "buildingName",
                        },
                        {
                            title: "등록일",
                            dataIndex: "createDate",
                        },
                        {
                            title: "이동",
                            dataIndex: "fileBoardSeq",
                            hidden: user?.division === "MANAGER",
                            render: (value, record, index) => (
                                <Dropdown menu={{items: itemMoveList(record.fileBoardSeq)}} trigger={["click"]}>
                                    <Button
                                        label="이동"
                                        color={"primary"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                    />
                                </Dropdown>
                            ),
                        },
                    ]}
                />
            </>
        </div>
    );
};

export {DriveBoardModal};
