import {useState} from "react";
import {Popconfirm} from "antd";
import classNames from "classnames/bind";

import {WaterPumpStatusProps} from "~/pages/ems/pump/water-pump.page.interface";

import styles from "./water-pump-status.module.scss";

const cx = classNames.bind(styles);

const WaterPumpStatus = ({index, data}: WaterPumpStatusProps) => {
    const [hover, setHover] = useState<boolean>(false);
    return (
        <div className={cx("container")}>
            <div className={cx("label-box")}>
                <span className={cx("label")}>{data.name}</span>
                <span className={cx("label", {status: data.power})}>{data.power ? "운전" : "정지"}</span>
            </div>
            <div className={cx("pump-status")}>
                <span className={cx("pump-name")}>1P</span>
                <img
                    className={cx("pump-icon")}
                    src={require(`../../../images/facility/pump_${data.pump1 ? "on" : "off"}.png`)}
                />
                <span className={cx("pump-power", {on: data.pump1})}>{data.pump1 ? "ON" : "OFF"}</span>
            </div>
            <div className={cx("pump-status")}>
                <span className={cx("pump-name")}>2P</span>
                <img
                    className={cx("pump-icon")}
                    src={require(`../../../images/facility/pump_${data.pump2 ? "on" : "off"}.png`)}
                    alt={"image"}
                />
                <span className={cx("pump-power", {on: data.pump2})}>{data.pump2 ? "ON" : "OFF"}</span>
            </div>
            <div className={cx("pump-status")} onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
                <span className={cx("pump-name")}>수위상태</span>
                {data.waterLevel !== 1 && data.waterLevel !== 3 ? (
                    <img
                        className={cx("tank-icon", {"tank-warning": data.waterLevel === 1 || data.waterLevel === 3})}
                        alt={"tank"}
                        src={require(`../../../images/facility/tank_${data.waterLevel}.png`)}
                    />
                ) : (
                    <Popconfirm
                        title={<div className={cx("pop-container")}>{data.createDate}</div>}
                        placement={index % 2 ? "rightTop" : "leftTop"}
                        icon={false}
                        showCancel={false}
                        okButtonProps={{style: {display: "none"}}}
                        open={hover}
                        description={
                            <div className={cx("content")}>
                                <img
                                    className={cx("warning-icon")}
                                    src={require(`../../../images/icons/warning-icon.png`)}
                                    alt={"image"}
                                />
                                <span className={cx("text")}>
                                    [{data.name}]{" "}
                                    <span className={cx("alarm-name")}>
                                        {data.waterLevel === 1 ? "저수위" : data.waterLevel === 3 ? "고수위" : ""} 알람
                                    </span>{" "}
                                    발생!
                                </span>
                            </div>
                        }
                    >
                        <img
                            className={cx("tank-icon", {
                                "tank-warning": data.waterLevel === 1 || data.waterLevel === 3,
                            })}
                            alt={"tank"}
                            src={require(`../../../images/facility/tank_${data.waterLevel}.png`)}
                        />
                    </Popconfirm>
                )}
            </div>
        </div>
    );
};

export {WaterPumpStatus};
