import {useEffect, useMemo, useState} from "react";
import {Cascader} from "antd";

import {httpRequest} from "~/fetch/common/http-request";

import {
    MachineCascaderBuildingResponse,
    MachineCascaderSectorResponse,
    MachineCascaderTrainerResponse,
} from "./machine-cascader.interface";

// 권역
const useMachineCascader = (width: number) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selected, setSelected] = useState<Array<string>>(["ALL"]);
    const [data, setData] = useState<{
        sector: MachineCascaderSectorResponse[];
        trainer: MachineCascaderTrainerResponse[];
        building: MachineCascaderBuildingResponse[];
    }>({
        sector: [],
        trainer: [],
        building: [],
    });

    const [selectList, setSelectList] = useState<
        Array<{
            value: string;
            label: string;
            children?: Array<{
                value: number | string;
                label: string;
                children?: Array<{value: number | string; label: string}>;
            }>;
        }>
    >([]);

    const sectorReq = useMemo(() => {
        return httpRequest<null, MachineCascaderSectorResponse[]>({
            url: "/sector/list",
        });
    }, []);
    const trainerReq = useMemo(() => {
        return httpRequest<null, MachineCascaderTrainerResponse[]>({
            url: "/common/trainerSector",
        });
    }, []);

    const buildingReq = useMemo(() => {
        return httpRequest<null, MachineCascaderBuildingResponse[]>({
            url: "/sector/manager",
        });
    }, []);

    const submit = async () => {
        try {
            setIsLoading(true);
            const sectors = await sectorReq.submit();
            const trainers = await trainerReq.submit();
            const buildings = await buildingReq.submit();
            setData({
                sector: sectors.data.sort((a, b) =>
                    a.sectorName === "폐업"
                        ? 1
                        : b.sectorName === "폐업"
                          ? -1
                          : a.sectorName.localeCompare(b.sectorName),
                ),
                trainer: trainers.data.sort((a, b) =>
                    a.trainerSectorName === "폐점"
                        ? 1
                        : b.trainerSectorName === "폐점"
                          ? -1
                          : a.trainerSectorName.localeCompare(b.trainerSectorName),
                ),
                building: buildings.data.sort((a, b) => a.buildingName.localeCompare(b.buildingName)),
            });
        } catch (e) {
            console.log("에러: ", e);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        submit();
    }, []);

    useEffect(() => {
        const sector = data.sector.map((item) => ({
            value: item.sectorId,
            label: item.sectorName,
            children: [
                {label: "전체", value: "ALL"},
                ...data.building
                    .filter((building) => building.sectorId === item.sectorId)
                    .map((building) => ({
                        value: building.buildingId,
                        label: building.buildingName,
                    })),
            ],
        }));

        const trainer = data.trainer.map((item) => ({
            value: item.trainerSectorId,
            label: item.trainerSectorName,
            children: [
                {label: "전체", value: "ALL"},
                ...data.building
                    .filter((building) => building.trainerSectorId === item.trainerSectorId)
                    .map((building) => ({
                        value: building.buildingId,
                        label: building.buildingName,
                    })),
                // .sort((a, b) => a.label.localeCompare(b.label, "ko-KR")),
            ],
        }));

        const building = data.building.map((item) => ({
            value: item.buildingId,
            label: item.buildingName,
            children: [
                {label: "전체", value: "ALL"},
                ...data.building
                    .filter((building) => building.buildingId === item.buildingId)
                    .map((building) => ({
                        value: building.buildingId,
                        label: building.buildingName,
                    })),
                // .sort((a, b) => a.label.localeCompare(b.label, "ko-KR")),
            ],
        }));

        sector.unshift({
            label: "전체",
            value: "ALL",
            children: [],
        });
        trainer.unshift({
            label: "전체",
            value: "ALL",
            children: [],
        });
        building.unshift({
            label: "전체",
            value: "ALL",
            children: [],
        });

        setSelectList([
            {
                value: "ALL",
                label: "전체",
            },
            {
                value: "TRAINER",
                label: "트레이너 권역",
                children: trainer,
            },
            {
                value: "SECTOR",
                label: "부문",
                children: sector,
            },
        ]);
    }, [data]);

    const handleResetSector = () => {
        setSelected(["ALL"]);
    };

    const MachineCascader = () => {
        return (
            <>
                <Cascader
                    style={{width: width, zIndex: 999}}
                    loading={isLoading}
                    value={selected}
                    options={selectList}
                    onChange={(e) => setSelected(e)}
                    allowClear={false}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    showSearch
                />
            </>
        );
    };

    return {
        selected,
        setSelected,
        reset: handleResetSector,
        MachineCascader,
    };
};

export {useMachineCascader};

// TODO 지점 리스트 API에 부문, 트레이너 권역별 ID 포함
