import {useEffect, useState} from "react";
import classNames from "classnames/bind";

import Button from "~/components/button/button";
import {CustomLabelTable} from "~/components/custom-label-table/custom-label-table";
import {SideModal} from "~/components/modal/side-modal";
import {PageTitle} from "~/components/page-title/page-title";
import {useInspectionRateDetail} from "~/data/inspection-rate/use-inspection-rate-detail";

import {InspectionRateDetailModalProps} from "./inspection.rate-detail.modal.interface";

import styles from "./inspection-rate-detail.modal.module.scss";

const cx = classNames.bind(styles);

const InspectionRateDetailModal = ({close, date, data}: InspectionRateDetailModalProps) => {
    const perPage = 20;
    // const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>([dayjs(), dayjs()]);
    const [page, setPage] = useState<number>(1);

    const {submitList, list, excel, isLoading} = useInspectionRateDetail({
        startDate: date[0].format("YYYY-MM-DD"),
        endDate: date[1].format("YYYY-MM-DD"),
        buildingId: data.buildingId,
        pageNo: page,
        recordPerPage: perPage,
        startRecordNo: 0,
    });

    useEffect(() => {
        submitList();
    }, [data]);

    return (
        <SideModal onClose={close} size="large">
            <>
                <PageTitle>이행률현황</PageTitle>
                <div className={cx("option-container")}>
                    <span className={cx("date-info")}>
                        {date[0].format("YYYY-MM-DD")} ~ {date[1].format("YYYY-MM-DD")}
                    </span>
                    <Button label="엑셀다운" color="primary" onClick={() => excel()} />
                </div>
                <div className={cx("table-container")}>
                    <CustomLabelTable
                        label={"지점 정보"}
                        className={cx("table")}
                        onPageChange={() => {
                            console.log();
                        }}
                        page={0}
                        perPage={0}
                        totalCount={0}
                        dataSource={[data]}
                        bordered
                        noPagination
                        pagination={false}
                        rowKey={"buildingId"}
                        columns={[
                            {
                                title: "부문",
                                dataIndex: "sectorName",
                                key: "sectorName",
                            },
                            {
                                title: "지점",
                                dataIndex: "buildingName",
                                key: "buildingName",
                            },
                            {
                                title: "이행률(%)",
                                dataIndex: "statusRatio",
                                key: "statusRatio",
                            },
                            {
                                title: "점검 개수",
                                dataIndex: "statusTotal",
                                key: "statusTotal",
                            },
                            {
                                title: "완료점검 개수",
                                dataIndex: "statusSuccess",
                                key: "statusSuccess",
                            },
                            {
                                title: "미완료점검 개수",
                                dataIndex: "statusIncomplete",
                                key: "statusIncomplete",
                            },
                            {
                                title: "양호",
                                dataIndex: "statusProtection",
                                key: "statusProtection",
                            },
                            {
                                title: "보통",
                                dataIndex: "statusNomal",
                                key: "statusNomal",
                            },
                            {
                                title: "불량",
                                dataIndex: "statusPoor",
                                key: "statusPoor",
                            },
                            {
                                title: "수치",
                                dataIndex: "numericalValue",
                                key: "numericalValue",
                            },
                            {
                                title: "해당없음",
                                dataIndex: "statusNone",
                                key: "statusNone",
                            },
                        ]}
                    />
                </div>
                <div className={cx("table-container")}>
                    <CustomLabelTable
                        label={"불량항목"}
                        className={cx("table")}
                        onPageChange={(e) => {
                            setPage(e);
                        }}
                        page={page}
                        perPage={perPage}
                        totalCount={list?.allRecordCnt ?? 0}
                        dataSource={list?.list.map((data, index) => ({
                            ...data,
                            index: index,
                        }))}
                        loading={isLoading}
                        bordered
                        rowKey={"index"}
                        pagination={false}
                        columns={[
                            {
                                title: "일자",
                                dataIndex: "orderDate",
                                key: "orderDate",
                            },
                            {
                                title: "점검명",
                                dataIndex: "checkFormName",
                                key: "checkFormName",
                            },
                            {
                                title: "항목명",
                                dataIndex: "checkFormItemName",
                                key: "checkFormItemName",
                            },
                            {
                                title: "위치",
                                dataIndex: "regionName",
                                key: "regionName",
                            },
                            {
                                title: "상태",
                                dataIndex: "status",
                                key: "status",
                            },
                        ]}
                    />
                </div>
            </>
        </SideModal>
    );
};

export {InspectionRateDetailModal};
