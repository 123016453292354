import {
    faCalculator,
    faChalkboard,
    faChartLine,
    faClipboardCheck,
    faClipboardList,
    faClone,
    faFileCircleCheck,
    faHelmetSafety,
    faLocationDot,
    faServer,
    faShop,
    IconDefinition,
} from "@fortawesome/free-solid-svg-icons";

interface Menu {
    name: string;
    path?: string;
    isToggle?: boolean;
    icon?: IconDefinition;
    children?: Array<Menu>;
}

const quickMenu: Array<Menu> = [
    {
        name: "nav.dashboard",
        path: "/",
        icon: faChartLine,
    },
    {
        name: "old-system",
        icon: faClone,
        path: "https://old.lottemartfms.com/",
    },
    // {
    //     name: "공지사항",
    //     path: "/notice",
    //     icon: faCircleExclamation,
    // },
    // {
    //     name: "캘린더",
    //     path: "/calendar",
    //     icon: faCalendar,
    // },
    // {
    //     name: "점포현황",
    //     path: "/shop",
    //     icon: faShop,
    // },
    {
        name: "nav.daily-work-plan",
        path: "/plan",
        icon: faClipboardList,
    },
    {
        name: "nav.inspection-rate",
        path: "/inspection-rate",
        icon: faFileCircleCheck,
    },
    // {
    //     name: "자료실",
    //     path: "/cloud",
    //     icon: faCloudArrowUp,
    // },
];

const member: Array<Menu> = [
    {
        name: "old-system",
        icon: faClone,
        path: "https://old.lottemartfms.com/",
    },
    {
        name: "자료실",
        icon: faClipboardCheck,
        isToggle: true,
        children: [
            {
                name: "자료실",
                path: "/drive",
            },
        ],
    },
    {
        name: "nav.inspection.title",
        icon: faHelmetSafety,
        isToggle: true,
        children: [
            {
                name: "nav.inspection.elevator.title",
                children: [
                    {
                        name: "nav.inspection.elevator.elevator-inspection",
                        path: "/check/elevator",
                    },
                    {
                        name: "정기점검 <월 1회>",
                        path: "/development",
                    },
                    {
                        name: "정밀점검 <반기 1회>",
                        path: "/development",
                    },
                ],
            },
            {
                name: "nav.inspection.legal-inspection.title",
                children: [
                    {
                        name: "nav.inspection.legal-inspection.instruction",
                        path: "/check/legal/instruction",
                    },
                    {
                        name: "nav.inspection.legal-inspection.instruction-history",
                        path: "/check/legal/history",
                    },
                    {
                        name: "nav.inspection.legal-inspection.inspection-history",
                        path: "/check/legal/report",
                    },
                    {
                        name: "nav.inspection.legal-inspection.form-management",
                        path: "/check/legal/form",
                    },
                    // {
                    //     name: "결재하기",
                    //     path: "/check/legal/approval",
                    // },
                ],
            },
            {
                name: "nav.inspection.checklist.title",
                children: [
                    {
                        name: "nav.inspection.checklist.instruction",
                        path: "/check/checklist/instruction",
                    },
                    {
                        name: "nav.inspection.checklist.instruction-history",
                        path: "/check/checklist/history",
                    },
                    {
                        name: "nav.inspection.checklist.inspection-history",
                        path: "/check/checklist/report",
                    },
                    {
                        name: "nav.inspection.checklist.form-management",
                        path: "/check/checklist/form",
                    },
                    // {
                    //     name: "결재하기",
                    //     path: "/check/checklist/approval",
                    // },
                ],
            },
            {
                name: "nav.inspection.general-inspection.title",
                children: [
                    {
                        name: "nav.inspection.additional-inspection.instruction",
                        path: "/check/extra/instruction",
                    },
                    {
                        name: "nav.inspection.additional-inspection.instruction-history",
                        path: "/check/extra/history",
                    },
                    {
                        name: "nav.inspection.additional-inspection.inspection-history",
                        path: "/check/extra/report",
                    },
                    {
                        name: "nav.inspection.additional-inspection.form-management",
                        path: "/check/extra/form",
                    },
                ],
            },
            {
                name: "nav.inspection.additional-inspection.title",
                children: [
                    {
                        name: "nav.inspection.general-inspection.instruction",
                        path: "/check/normal/instruction",
                    },
                    {
                        name: "nav.inspection.general-inspection.instruction-history",
                        path: "/check/normal/history",
                    },
                    {
                        name: "nav.inspection.general-inspection.inspection-history",
                        path: "/check/normal/report",
                    },
                    {
                        name: "nav.inspection.general-inspection.form-management",
                        path: "/check/normal/form",
                    },
                ],
            },
        ],
    },
    {
        name: "nav.inspection-rate",
        icon: faFileCircleCheck,
        isToggle: true,
        children: [
            {
                name: "nav.inspection-rate",
                path: "/inspection-rate",
            },
        ],
    },
    {
        name: "nav.merge-board",
        icon: faClipboardCheck,
        isToggle: true,
        children: [
            {
                name: "nav.merge-board",
                path: "/merge",
            },
        ],
    },
    {
        name: "nav.budget.title",
        icon: faCalculator,
        isToggle: true,
        children: [
            {
                name: "nav.budget.total-maintenance-cost",
                path: "/cost/integrated",
            },
            {
                name: "nav.budget.maintenance-equipment",
                path: "/cost/maintenance",
            },
            {
                name: "nav.budget.monthly-plan",
                path: "/cost/facility",
            },
            {
                name: "nav.budget.trainer-reserve",
                path: "/cost/trainer",
            },
            {
                name: "nav.budget.monthly-plan-management",
                path: "/cost/monthly",
            },
        ],
    },
    {
        name: "nav.daily-work-plan",
        icon: faClipboardList,
        isToggle: true,
        children: [
            {
                name: "nav.daily-work-plan",
                path: "/plan",
            },
            {
                name: "nav.hr-setting",
                path: "/hr",
                // icon: faLocationDot,
            },
        ],
    },
    {
        name: "nav.store-status",
        icon: faShop,
        isToggle: true,
        children: [
            {
                name: "nav.store-status",
                path: "/store",
            },
            {
                name: "nav.building-setting",
                path: "/building",
                // icon: faLocationDot,
            },
        ],
    },
    {
        name: "nav.machine.status",
        icon: faServer,
        isToggle: true,
        children: [
            {
                name: "nav.machine.status",
                path: "/equipment",
            },
            {
                name: "배수펌프",
                path: "/pump",
            },
        ],
    },
    {
        name: "nav.area-setting",
        path: "/area",
        icon: faLocationDot,
    },
    {
        name: "nav.board.title",
        icon: faChalkboard,
        isToggle: true,
        children: [
            {
                name: "nav.board.notice-board",
                path: "/board/notice",
            },
            {
                name: "nav.board.inquiry-board",
                path: "/board/inquiry",
            },
            {
                name: "nav.board.surplus-board",
                path: "/board/surplus",
            },
            {
                name: "nav.board.manual-board",
                path: "/board/manual",
            },
            {
                name: "업체연락망 게시판",
                path: "/board/contact",
            },
        ],
    },
];

const hiddenMenuPathForManager: Array<string> = [
    "/cost/integrated",
    "/cost/trainer",
    "/plan",
    "/building",
    "/store",
    "/hr",
];
const hiddenMenuPathForMember: Array<string> = [
    "/check/legal/instruction",
    "/check/legal/history",
    "/check/legal/form",
    "/check/checklist/instruction",
    "/check/checklist/history",
    "/check/checklist/form",
    "/check/extra/instruction",
    "/check/extra/history",
    "/check/extra/form",
    "/check/normal/instruction",
    "/check/normal/history",
    "/area",
];
const hiddenMenuPathNameForMember: Array<string> = ["nav.inspection.additional-inspection.title"];
const hiddenMenuPathNameForNotMember: Array<string> = ["nav.daily-work-plan", "nav.store-status"];

const mobileMenu: Menu[] = [
    {
        name: "오늘의 점검사항",
        path: "/mobile/check/all-list",
    },
    {
        name: "승강기점검",
        path: "/mobile/check/elevator",
    },
    {
        name: "법정점검",
        path: "/mobile/check/list?type=legal",
    },
    {
        name: "체크리스트",
        path: "/mobile/check/list?type=checklist",
    },
    {
        name: "일반점검",
        path: "/mobile/check/list?type=extra",
    },
    {
        name: "추가점검",
        path: "/mobile/check/list?type=normal",
    },
    {
        name: "설비현황",
        path: "/mobile/equipment",
    },
];

export type {Menu};

export {
    hiddenMenuPathForManager,
    hiddenMenuPathForMember,
    hiddenMenuPathNameForMember,
    hiddenMenuPathNameForNotMember,
    member,
    mobileMenu,
    quickMenu,
};
