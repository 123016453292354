import React, {useEffect, useState} from "react";
import {faDownload, faPaperclip, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button as ButtonAntd, Input, List, Radio, Upload} from "antd";
import {UploadChangeParam} from "antd/es/upload";
import classNames from "classnames/bind";

import Button from "~/components/button";
import {MergeGridItem} from "~/components/merge/merge-grid-item";
import {SideModal} from "~/components/modal/side-modal";
import {PageTitle} from "~/components/page-title";
import {singleFileUpload} from "~/fetch/single-file-upload";

import {AddNewFileProps} from "./add-new-file.interface";

import styles from "./add-new-file.modal.module.scss";
import stylesGrid from "~/components/merge/merge-grid-item/merge-grid-item.module.scss";

const gridCx = classNames.bind(stylesGrid);
const cx = classNames.bind(styles);

const {Dragger} = Upload;

const AddNewFileModal = ({onClose, onSave, refresh}: AddNewFileProps) => {
    const [files, setFiles] = useState<
        Array<{
            fileOriginalName: string;
            filePath: string;
        }>
    >([]);
    const [title, setTitle] = useState<string>("");
    const [contents, setContents] = useState<string>("");
    const [lockYn, setLockYn] = useState<"Y" | "N">("N");
    const [uploadProgress, setUploadProgress] = useState<{percent: number; status: boolean}>({
        percent: 0,
        status: false,
    });

    const handleFileChange = (param: UploadChangeParam) => {
        if (param.file.status === "done") {
            setTimeout(
                () =>
                    setUploadProgress({
                        percent: 0,
                        status: false,
                    }),
                100,
            );
            setFiles((prevFiles) => {
                return [
                    ...prevFiles,
                    {
                        fileOriginalName: param.file.name,
                        filePath: param.file.response.filePath,
                    },
                ];
            });
            setUploadProgress({percent: 0, status: false});
        }
        if (param.file.status === "removed") {
            setFiles((prevFiles) => {
                return prevFiles.filter((f) => f.filePath !== param.file.response.filePath);
            });
        }
    };
    const handleRemoveFile = (file: {fileOriginalName: string; filePath: string}) => {
        setFiles((p) => {
            return p.filter((item) => item.fileOriginalName !== file.fileOriginalName);
        });
        refresh();
    };

    const handleSave = () => {
        onSave({
            contents: contents,
            fileBoardDataDTOList: files,
            lockYn: lockYn,
            title: title,
        });
        refresh();
    };

    useEffect(() => {
        if (uploadProgress.percent >= 100) setUploadProgress({percent: 0, status: false});
    }, [uploadProgress]);

    return (
        <SideModal onClose={onClose} extraButton={<Button label={"저장하기"} onClick={handleSave} />}>
            <PageTitle>파일추가</PageTitle>
            <div className={cx("container")}>
                <MergeGridItem name={"제목"} span>
                    <Input
                        className={cx("input")}
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder={"제목을 입력하세요"}
                    />
                </MergeGridItem>
                <MergeGridItem name={"공개여부"} span>
                    <Radio.Group defaultValue={"N"} onChange={(e) => setLockYn(e.target.value)}>
                        <Radio value="N">공개</Radio>
                        <Radio value="Y">비공개</Radio>
                    </Radio.Group>
                </MergeGridItem>
                <MergeGridItem name={"첨부파일"} span extraClassName={"upload-container"}>
                    <div className={gridCx("file-container")}>
                        {uploadProgress.status && (
                            <div className={cx("uploading-status")}>업로드 중입니다... ({uploadProgress.percent}%)</div>
                        )}
                        <Dragger
                            className={gridCx("upload-dragger")}
                            customRequest={(options) =>
                                singleFileUpload({
                                    ...options,
                                    onProgress: (e) => {
                                        if (e.percent) {
                                            setUploadProgress({
                                                percent: Number(e.percent.toFixed(2)),
                                                status: true,
                                            });
                                            if (e.percent >= 100) {
                                                setUploadProgress({
                                                    percent: 0,
                                                    status: false,
                                                });
                                            }
                                        }
                                    },
                                })
                            }
                            multiple
                            onChange={handleFileChange}
                            showUploadList={false}
                        >
                            {files.length ? (
                                <List
                                    itemLayout={"horizontal"}
                                    dataSource={files}
                                    className={gridCx("file-list")}
                                    renderItem={(file, index) => (
                                        <List.Item
                                            className={gridCx("file-list-item")}
                                            key={index}
                                            actions={[
                                                <ButtonAntd
                                                    type="link"
                                                    key={index}
                                                    icon={<FontAwesomeIcon icon={faDownload} />}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        // fileDownload(file.id);
                                                    }}
                                                />,
                                                <ButtonAntd
                                                    type="link"
                                                    danger
                                                    key={index}
                                                    icon={<FontAwesomeIcon icon={faTrashCan} />}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleRemoveFile(file);
                                                    }}
                                                />,
                                            ]}
                                        >
                                            <List.Item.Meta
                                                avatar={<FontAwesomeIcon icon={faPaperclip} />}
                                                title={file.fileOriginalName}
                                                className={gridCx("file-list-item-meta")}
                                                style={{textAlign: "left"}}
                                            />
                                        </List.Item>
                                    )}
                                />
                            ) : (
                                <>
                                    <p className="ant-upload-text">파일을 끌어다 놓거나 클릭하여 파일을 선택하세요.</p>
                                    <p className="ant-upload-hint">각 파일의 용량 제한은 500MB입니다.</p>
                                    <p className="ant-upload-hint">
                                        용량이 초과될 경우 분할 압축을 하여 업로드 해주십시오.
                                    </p>
                                </>
                            )}
                        </Dragger>
                    </div>
                </MergeGridItem>
                <MergeGridItem name={"내용"} span extraClassName={"padding-container"}>
                    <Input.TextArea
                        className={cx("text-area")}
                        placeholder={"내용을 입력하세요"}
                        value={contents}
                        onChange={(e) => setContents(e.target.value)}
                    />
                </MergeGridItem>
            </div>
        </SideModal>
    );
};

export {AddNewFileModal};
