import React, {useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Spin} from "antd";
import classNames from "classnames/bind";
import dayjs from "dayjs";
import domtoimage from "dom-to-image";
import {saveAs} from "file-saver";

import Button from "~/components/button/button";
import {CnTableNoPadding} from "~/components/cn-table-nopadding/cn-table-nopadding";
import {PageTitle} from "~/components/page-title";
import {useMachineBoardList} from "~/data/machine/board/use-machine";

import {useMachineDetail} from "./use-detail";

import styles from "./equipment-detail.module.scss";

const cx = classNames.bind(styles);

const EquipmentDetailModal = ({onClose, machineId}: any) => {
    const {t} = useTranslation();

    // 기본정보
    const captureRef = useRef<HTMLDivElement | null>(null);
    // 추가정보
    const additionalRef = useRef<HTMLDivElement | null>(null);
    // 장비이력
    const historyRef = useRef<HTMLDivElement | null>(null);

    const [isInitialize, setIsInitialize] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [newMachineId, setNewMachineId] = useState(machineId);

    // 상세 정보
    const [submit, response, isDetailLoading] = useMachineDetail(newMachineId); // here00
    const detailData = response?.machineDTO;

    // 초기 셋팅
    useEffect(() => {
        setIsLoading(true);
        setIsInitialize(true);
        submit();
        submitA();
    }, []);
    useEffect(() => {
        submit();
        submitA();
    }, [newMachineId]);

    useEffect(() => {
        if (!isLoading && isInitialize) setTimeout(() => onClose(), 500);
    }, [isLoading]);

    useEffect(() => {
        const handleCloseESC = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                setIsLoading(false);
            }
        };

        if (isLoading) {
            document.addEventListener("keydown", handleCloseESC);
        }
        return () => {
            document.removeEventListener("keydown", handleCloseESC);
        };
    }, [isLoading]);

    const handleClose = () => {
        setIsLoading(false);
        captureRef.current = null; // 모달이 닫힌 후 ref를 다시 사용할 수 있도록 처리
    };

    // 캡쳐
    const handleCapture = async () => {
        if (captureRef.current && additionalRef.current && historyRef.current && detailData) {
            // 캡처를 위한 강제 스타일 적용
            captureRef.current.style.height = captureRef.current.scrollHeight + "px"; // 실제 높이 반영
            additionalRef.current.style.height = additionalRef.current.scrollHeight + "px"; // 실제 높이 반영
            historyRef.current.style.height = historyRef.current.scrollHeight + "px"; // 실제 높이 반영

            // 캡처 실행
            const infoDataUrl = await domtoimage.toPng(captureRef.current);
            const addDataUrl = await domtoimage.toPng(additionalRef.current);
            const historyDataUrl = await domtoimage.toPng(historyRef.current);
            saveAs(infoDataUrl, `[${detailData.buildingName ?? ""}] ${detailData.machineName ?? ""}_기본정보.png`);
            saveAs(addDataUrl, `[${detailData.buildingName ?? ""}] ${detailData.machineName ?? ""}_추가정보.png`);
            saveAs(historyDataUrl, `[${detailData.buildingName ?? ""}] ${detailData.machineName ?? ""}_장비이력.png`);
        }
    };

    // 설비목록
    const perPage = useMemo(() => 15, []);
    const [page, setPage] = useState(1);
    const [submitA, responseA, isLoadingA] = useMachineBoardList({
        pageNo: page, // 페이지 넘버 - 1(ALL)
        trainerSectorId: null, // 트레이너 권역
        searchMachineSectorId: null, // 부문
        searchMachineBuildingId: machineId.buildingId, // 지점 here00
        searchProcessCode: null, // 공정
        searchMachineCategoryCode: machineId.machineCategoryCode, // 카테고리 - ALL here00
        searchMachineUseYn: "ALL", // 사용여부 - ALL
        isNoImage: "ALL", // 설비 사진 - ALL
    });

    useEffect(() => {
        // window.scrollTo(0, 0);
        submitA();
    }, [page]);

    return (
        <div className={cx("container", {active: isLoading})}>
            <div
                className={cx("inner", {active: isLoading}, "large")}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                <div className={cx("contents-container")} ref={captureRef}>
                    <PageTitle>설비현황상세</PageTitle>
                    {isDetailLoading ? (
                        <div className={cx("loading")}>
                            <Spin size="large" />
                        </div>
                    ) : (
                        <>
                            <div className={cx("info")} ref={captureRef}>
                                <h3>기본정보</h3>
                                <table className={cx("table")}>
                                    <colgroup>
                                        <col width="40%" />
                                        <col width="15%" />
                                        <col width="15%" />
                                        <col width="15%" />
                                        <col width="15%" />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <td rowSpan={10} className={cx("image-cell")}>
                                                {detailData?.base64Image === "" ? (
                                                    <span>등록된 이미지가 없습니다.</span>
                                                ) : (
                                                    <img
                                                        style={{width: "100%"}}
                                                        src={
                                                            detailData?.base64Image.startsWith("/9j/")
                                                                ? `data:image/jpeg;base64,${detailData?.base64Image}`
                                                                : detailData?.base64Image.startsWith("iVBORw0KGgo")
                                                                  ? `data:image/png;base64,${detailData?.base64Image}`
                                                                  : `data:image/webp;base64,${detailData?.base64Image}`
                                                        }
                                                    />
                                                )}
                                            </td>
                                            <th>지점명</th>
                                            <td>{detailData?.buildingName}</td>
                                            <th>장비명</th>
                                            <td>{detailData?.machineName}</td>
                                        </tr>
                                        <tr>
                                            <th>카테고리</th>
                                            <td colSpan={3}>{detailData?.machineCategoryName}</td>
                                        </tr>
                                        <tr>
                                            <th>제조사</th>
                                            <td>{detailData?.machineManufactureCompany}</td>
                                            <th>구역</th>
                                            <td>{detailData?.machineRegionName}</td>
                                        </tr>
                                        <tr>
                                            <th>설치연월</th>
                                            <td>
                                                {dayjs(detailData?.machineInstallDate).isValid()
                                                    ? dayjs(detailData?.machineInstallDate).format("YYYY-MM")
                                                    : ""}
                                            </td>
                                            <th>제조연월</th>
                                            <td>
                                                {dayjs(detailData?.machineManufactureDate).isValid()
                                                    ? dayjs(detailData?.machineManufactureDate).format("YYYY-MM")
                                                    : ""}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>제조번호</th>
                                            <td colSpan={3}>{detailData?.machineManufactureNumber}</td>
                                        </tr>
                                        <tr>
                                            <th>모델명</th>
                                            <td colSpan={3}>{detailData?.machineModelName}</td>
                                        </tr>
                                        <tr>
                                            <th>설치업체</th>
                                            <td colSpan={3}>{detailData?.machineInstallCompany}</td>
                                        </tr>
                                        <tr>
                                            <th>장비코드</th>
                                            <td colSpan={3}>{detailData?.machineLocalCode}</td>
                                        </tr>
                                        <tr>
                                            <th>사용여부</th>
                                            <td>{detailData?.machineUseYn}</td>
                                            <th>보유수량</th>
                                            <td>{detailData?.machineQuantitiy}</td>
                                        </tr>
                                        <tr>
                                            <th>비고</th>
                                            <td colSpan={3}>{detailData?.machineNote}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={cx("info")}>
                                <h3>설비목록</h3>
                                <div className={cx("table-container")}>
                                    <CnTableNoPadding
                                        style={{padding: "0"}}
                                        totalCount={responseA?.allRecordCnt}
                                        onPageChange={(page) => setPage(page)}
                                        perPage={perPage}
                                        page={page}
                                        dataSource={responseA?.resultList.map((row: any) => {
                                            row["key"] = row.machineId;
                                            return row;
                                        })}
                                        pagination={false}
                                        loading={isLoadingA}
                                        onRow={(data, index) => ({
                                            onClick: () =>
                                                setNewMachineId({
                                                    machineCategoryCode: data!.machineCategoryCode,
                                                    machineId: data!.machineId,
                                                    companyId: data!.companyId,
                                                    buildingId: data!.buildingId,
                                                }),
                                        })}
                                        columns={[
                                            {
                                                title: "No",
                                                key: "index",
                                                render: (_, __, index) =>
                                                    Number((page - 1) * perPage + index + 1).toLocaleString(),
                                                align: "center",
                                            },
                                            {
                                                title: t("equipment.equipment-name"),
                                                dataIndex: "machineName",
                                                key: "machineName",
                                                align: "center",
                                            },
                                            {
                                                title: t("equipment.manufacturer"),
                                                dataIndex: "machineManufactureCompany",
                                                key: "machineManufactureCompany",
                                                align: "center",
                                            },
                                            {
                                                title: t("equipment.installation-date"),
                                                dataIndex: "machineInstallDate",
                                                key: "machineInstallDate",
                                                align: "center",
                                                render: (date) =>
                                                    dayjs(date).isValid() ? dayjs(date).format("YYYY-MM") : "",
                                            },
                                            {
                                                title: t("equipment.installer"),
                                                dataIndex: "machineInstallCompany",
                                                key: "machineInstallCompany",
                                                align: "center",
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                            <div className={cx("info")} ref={additionalRef}>
                                {detailData?.machineCheckDTOList.filter((m) => m.isDel === "N").length !== 0 && (
                                    <>
                                        <h3>추가정보</h3>
                                        <table className={cx("table")}>
                                            <colgroup>
                                                <col width="30%" />
                                                <col width="70%" />
                                            </colgroup>
                                            <tbody>
                                                {detailData?.machineCheckDTOList
                                                    .filter((m) => m.isDel === "N")
                                                    .map(
                                                        (
                                                            el: {machineCheckName: string; machineCheckAnswer: string},
                                                            index: number,
                                                        ) => (
                                                            <tr key={index}>
                                                                <th>{el.machineCheckName}</th>
                                                                <td>{el.machineCheckAnswer}</td>
                                                            </tr>
                                                        ),
                                                    )}
                                                {detailData?.machineAddDTOList?.map(
                                                    (el: {
                                                        machineAddId: number;
                                                        additionalKey: string;
                                                        additionalValue: string;
                                                    }) => (
                                                        <tr key={el.machineAddId}>
                                                            <th>{el.additionalKey}</th>
                                                            <td>{el.additionalValue}</td>
                                                        </tr>
                                                    ),
                                                )}
                                            </tbody>
                                        </table>
                                    </>
                                )}
                            </div>

                            <div className={cx("info")} ref={historyRef}>
                                {detailData?.machineHistoryDTOList.filter((m) => m.isDel === "N").length !== 0 && (
                                    <>
                                        <h3>장비이력</h3>
                                        <table className={cx("table")}>
                                            <colgroup>
                                                <col width="3%" />
                                                <col width="10%" />
                                                <col width="10%" />
                                                <col width="10%" />
                                                <col width="42%" />
                                                <col width="25%" />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>날짜</th>
                                                    <th>비용</th>
                                                    <th>구분</th>
                                                    <th>업무내역</th>
                                                    <th>비고</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {detailData?.machineHistoryDTOList
                                                    .filter((m) => m.isDel === "N")
                                                    .map(
                                                        (
                                                            el: {
                                                                machineHistoryDate: number;
                                                                machineHistoryCost: number;
                                                                machineHistoryContent: string;
                                                                machineHistoryNote: string;
                                                                type: string;
                                                            },
                                                            index: number,
                                                        ) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    {dayjs(new Date(el.machineHistoryDate)).format(
                                                                        "YYYY.MM.DD",
                                                                    )}
                                                                </td>
                                                                <td>{el.machineHistoryCost.toLocaleString()}</td>
                                                                <td>
                                                                    {el.type === "self"
                                                                        ? "자체개선"
                                                                        : el.type === "construction"
                                                                          ? "실제공사"
                                                                          : ""}
                                                                </td>
                                                                <td>{el.machineHistoryContent}</td>
                                                                <td>{el.machineHistoryNote}</td>
                                                            </tr>
                                                        ),
                                                    )}
                                            </tbody>
                                        </table>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                    <div className={cx("info-right")}>
                        <div className={cx("button-container")}>
                            <Button onClick={handleCapture} label="캡쳐하기" />
                            <Button onClick={handleClose} label="닫기" color={"primary"} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export {EquipmentDetailModal};
