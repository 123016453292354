import {useCallback, useEffect, useState} from "react";
import {faRotate} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Empty, Table} from "antd";
import classNames from "classnames/bind";
import dayjs, {Dayjs} from "dayjs";
import Swal from "sweetalert2";

import Button from "~/components/button";
import {CnRangePicker} from "~/components/cn-range-picker";
import {CnSelect} from "~/components/cn-select";
import {CustomLabelTable} from "~/components/custom-label-table/custom-label-table";
import {FilterArea} from "~/components/filter-area";
import {PageTitle} from "~/components/page-title";
import {useWaterPump} from "~/data/pump/use-water-pump";
import {PumpStateType} from "~/data/pump/use-water-pump.interface";
import {WaterPumpWarningModal} from "~/pages/ems/pump/modal/water-pump-warning.modal";
import {WaterPumpStatus} from "~/pages/ems/pump/water-pump-status";

import styles from "./water-pump.page.module.scss";

const cx = classNames.bind(styles);

const WaterPumpPage = () => {
    const perPage = 10;
    const [page, setPage] = useState<number>(1);
    const [filter, setFilter] = useState<{
        buildingId: number | null;
        dateRange: [Dayjs, Dayjs];
        status: "run" | "stop" | "alarm" | null;
    }>({
        buildingId: null,
        dateRange: [dayjs(new Date()).add(-7, "d"), dayjs(new Date())],
        status: null,
    });
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [alarmCheck, setAlarmCheck] = useState<boolean>(false);
    const [warningModal, setWarningModal] = useState<PumpStateType | false>(false);

    const {pumpList, alarmList, shopList, getPumpStatus, getAlarmList, downloadExcel, syncTime, loading} = useWaterPump(
        {
            buildingId: filter.buildingId,
            state: filter.status,
            startDate: filter.dateRange ? filter.dateRange[0].format("YYYY-MM-DD") : null,
            endDate: filter.dateRange ? filter.dateRange[1].format("YYYY-MM-DD") : null,
            pageNo: page,
        },
    );

    const handleReset = useCallback(() => {
        setFilter({
            buildingId: shopList.length ? shopList[0].buildingId : null,
            dateRange: [dayjs(new Date()).add(-7, "d"), dayjs(new Date())],
            status: null,
        });
    }, [shopList]);
    const handleInquiry = () => {
        getAlarmList();
        setPage(1);
    };

    useEffect(() => {
        handleInquiry();
    }, [filter]);
    useEffect(() => {
        getAlarmList();
        setSelectedRows([]);
    }, [page]);
    useEffect(() => {
        if (shopList.length) setFilter((p) => ({...p, buildingId: shopList[0].buildingId ?? null}));
    }, [shopList]);
    useEffect(() => {
        getPumpStatus();
        const interval = setInterval(getPumpStatus, 1000 * 60);
        return () => clearInterval(interval); // 컴포넌트 언마운트 시 정리
    }, []);
    useEffect(() => {
        if (pumpList?.pumpStateList.length) {
            pumpList?.pumpStateList.forEach((data) => {
                if (data.alarm === 1) setWarningModal(data);
            });
        }
    }, [pumpList]);

    return (
        <div>
            {!alarmCheck && warningModal && (
                <WaterPumpWarningModal
                    data={warningModal}
                    close={() => setWarningModal(false)}
                    setAlarmCheck={setAlarmCheck}
                />
            )}
            <PageTitle>
                <div className={cx("title-container")}>
                    배수펌프 상태 모니터링 및 점검내역 조회
                    <div className={cx("sync-time")}>
                        최근 동기화 : {syncTime.format("YYYY-MM-DD HH:mm")}
                        <FontAwesomeIcon
                            icon={faRotate}
                            className={cx("sync-icon")}
                            onClick={async () => {
                                await getPumpStatus();
                            }}
                        />
                    </div>
                </div>
            </PageTitle>
            <FilterArea>
                <div className={cx("filter-inner")}>
                    <CnRangePicker
                        label={"기간"}
                        value={filter.dateRange}
                        onChange={(e) => {
                            setFilter((p) => ({...p, dateRange: e}));
                        }}
                        allowClear={false}
                    />
                    <CnSelect
                        label={"지점"}
                        placeholder={"지점선택"}
                        value={filter.buildingId || ""}
                        onChange={(e) => setFilter((p) => ({...p, buildingId: e || null}))}
                        options={shopList.map((item) => ({
                            label: item.buildingName,
                            value: item.buildingId,
                        }))}
                    />
                    <CnSelect
                        label={"상태"}
                        value={filter.status || ""}
                        onChange={(e) => {
                            setFilter((p) => ({...p, status: e || null}));
                        }}
                        options={[
                            {
                                label: "전체",
                                value: "",
                            },
                            {
                                label: "운전",
                                value: "run",
                            },
                            {
                                label: "정지",
                                value: "stop",
                            },
                            {
                                label: "알람",
                                value: "alarm",
                            },
                        ]}
                    />
                    <div className={cx("button-container")}>
                        <Button label={"조회"} color={"primary"} onClick={handleInquiry} />
                        <Button label={"검색조건 초기화"} onClick={handleReset} />
                    </div>
                </div>
            </FilterArea>
            <div className={cx("pump-status-container")}>
                <span className={cx("title")}>실시간 배수펌프 상태</span>
                {!pumpList?.pumpStateList.length && (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={cx("empty")} />
                )}
                {pumpList?.pumpStateList.map((item, index) => (
                    <WaterPumpStatus index={index + 1} data={item} key={index} />
                ))}
            </div>
            <div className={cx("table-container")}>
                <span className={cx("title")}>알람 상세내역</span>
                <CustomLabelTable
                    label={
                        <>
                            <span>
                                {alarmList ? alarmList?.allRecordCnt ?? 0 : 0} 건 조회됨 ({page}/
                                {Math.ceil(
                                    (alarmList ? (alarmList.allRecordCnt ?? 0 ? alarmList?.allRecordCnt ?? 0 : 1) : 1) /
                                        perPage,
                                )}{" "}
                                페이지)
                            </span>
                            <div>
                                <span style={{marginRight: 5}}> {selectedRows.length} 건 선택됨</span>
                                <Button
                                    label={"내려받기"}
                                    color={"primary"}
                                    onClick={async () => {
                                        if (selectedRows.length) await downloadExcel(selectedRows);
                                        else
                                            Swal.fire({
                                                text: "알람을 선택해주세요",
                                                confirmButtonText: "확인",
                                            });
                                    }}
                                />
                            </div>
                        </>
                    }
                    totalCount={alarmList?.allRecordCnt ?? 0}
                    rowSelection={{
                        selectedRowKeys: selectedRows,
                        onChange: (selectedRowKeys: React.Key[]) => {
                            setSelectedRows(selectedRowKeys as number[]);
                        },
                    }}
                    perPage={perPage}
                    loading={loading}
                    page={page}
                    onPageChange={setPage}
                    pagination={false}
                    // onRow={(record) => ({
                    //     className: cx({unchecked: record.isChecked === "N"}),
                    // })}
                    rowKey={"id"}
                    dataSource={alarmList?.pumpAlarmList ?? []}
                    columns={[
                        {
                            title: "No",
                            render: (_, __, index) => Number((page - 1) * perPage + index + 1).toLocaleString(),
                            align: "center",
                        },
                        {
                            title: "발생일시",
                            dataIndex: "eventTime",
                            align: "center",
                        },
                        {
                            title: "권역",
                            dataIndex: "trainerSectorName",
                            align: "center",
                        },
                        {
                            title: "지점",
                            dataIndex: "buildingName",
                            align: "center",
                        },
                        {
                            title: "작동 상태 요약",
                            dataIndex: "content",
                            align: "center",
                        },
                        {
                            title: "알람 상세 내역",
                            dataIndex: "detail",
                            align: "center",
                        },
                        Table.SELECTION_COLUMN,
                    ]}
                />
            </div>
        </div>
    );
};

export {WaterPumpPage};
