import {useCallback} from "react";
import classNames from "classnames/bind";

import Button from "~/components/button";
import {WaterPumpWarningModalProps} from "~/pages/ems/pump/modal/water-pump-warning.modal.interface";

import styles from "./water-pump-warning.modal.module.scss";

const cx = classNames.bind(styles);

const WaterPumpWarningModal = ({close, setAlarmCheck, data}: WaterPumpWarningModalProps) => {
    const handleClose = useCallback(() => {
        close();
        setAlarmCheck(true);
        setTimeout(() => setAlarmCheck(false), 1000 * 60 * 5);
    }, []);

    return (
        <div className={cx("background")}>
            <div className={cx("container")}>
                <div className={cx("header")}>
                    <img
                        src={require("../../../../images/icons/warning-icon.png")}
                        alt="water-pump-warning"
                        className={cx("icon")}
                    />
                </div>
                <p className={cx("warning")}>Warning!</p>
                <p className={cx("date")}>{data.createDate}</p>
                <p className={cx("text")}>
                    [{data.name}] <span>{data.message}</span>
                    {/*<span>{data.waterLevel === 1 ? "저수위" : "고수위"} 알람</span> 발생*/}
                </p>
                <Button label={"확인"} size={"large"} onClick={handleClose} />
            </div>
        </div>
    );
};

export {WaterPumpWarningModal};
