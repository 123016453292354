import {useCallback, useEffect, useState} from "react";
import {message} from "antd";
import dayjs, {Dayjs} from "dayjs";
import Swal from "sweetalert2";

import {
    WaterPumpAlarmListResponse,
    WaterPumpListRequest,
    WaterPumpListResponse,
    WaterPumpShopResponse,
} from "~/data/pump/use-water-pump.interface";
import {httpRequest} from "~/fetch/common/http-request";

const useWaterPump = (config: WaterPumpListRequest) => {
    const [pumpList, setPumpList] = useState<WaterPumpListResponse | null>(null);
    const [alarmList, setAlarmList] = useState<WaterPumpAlarmListResponse | null>(null);
    const [shopList, setShopList] = useState<WaterPumpShopResponse[]>([]);
    const [syncTime, setSyncTime] = useState<Dayjs>(dayjs());
    const [loading, setLoading] = useState<boolean>(false);

    // 실시간 배수펌프 상태
    const getPumpStatus = useCallback(async () => {
        setLoading(true);
        return await httpRequest<WaterPumpListRequest, WaterPumpListResponse>({
            method: "GET",
            url: "/lotte/pump/state/list",
            params: config,
        })
            .submit()
            .then((res) => {
                setPumpList(res.data);
                setSyncTime(dayjs());
                message.config({top: 100});
                message.success("데이터가 최신 상태입니다.");
            })
            .catch((err) => console.error(err))
            .finally(() => setLoading(false));
    }, [config]);

    // 알람 상세내역
    const getAlarmList = useCallback(async () => {
        setLoading(true);
        return await httpRequest<WaterPumpListRequest, WaterPumpAlarmListResponse>({
            method: "GET",
            url: "/lotte/pump/alarm/list",
            params: config,
        })
            .submit()
            .then((res) => {
                if (res.data.result === "FAIL") {
                    console.log("서버오류");
                } else {
                    setAlarmList({
                        ...res.data,
                        pumpAlarmList: res.data.pumpAlarmList.map((item, index) => ({
                            ...item,
                            index: index + 1,
                        })),
                    });
                }
            })
            .catch((err) => console.error(err))
            .finally(() => setLoading(false));
    }, [config]);

    // 배수펌프가 있는 지점만 조회
    const getShopList = useCallback(async () => {
        return await httpRequest<null, WaterPumpShopResponse[]>({
            method: "GET",
            url: "/sector/manager/pump",
        })
            .submit()
            .then((res) => setShopList(res.data))
            .catch((err) => console.error(err));
    }, []);

    // 엑셀 내려받기
    const downloadExcel = useCallback(
        async (selectedRows: number[]) => {
            await httpRequest<{ids: number[]}, Blob>({
                method: "GET",
                url: "/lotte/pump/excel",
                params: {ids: selectedRows.join(",")},
                responseType: "blob",
            })
                .submit()
                .then((res) => {
                    // 파일 다운로드 처리
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    // 파일명추출
                    const contentType = res.headers["content-type"];
                    let fileName = "downloaded_file";
                    if (contentType) {
                        const matches = /filename=([^;]*)/.exec(contentType);
                        if (matches != null && matches[1]) {
                            fileName = matches[1].trim().replace(/['"]/g, "");
                        }
                    }

                    link.setAttribute("download", decodeURIComponent(fileName.replace(/\+/g, " ")));
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch((err) => {
                    console.error(err);
                    Swal.fire({
                        text: "오류가 발생하였습니다. 다시 시도해주세요",
                        confirmButtonText: "확인",
                    });
                });
        },
        [config],
    );

    // 알람 확인
    const checkAlarm = useCallback(async (id: number) => {
        await httpRequest<{id: number}, string>({
            method: "PUT",
            url: `/lotte/pump/update`,
            data: {
                id,
            },
        })
            .submit()
            .then((res) => {
                if (res.data === "success") {
                    message.success("확인되었습니다.");
                } else {
                    Swal.fire({
                        text: "오류가 발생하였습니다. 다시 시도해주세요",
                        confirmButtonText: "확인",
                    });
                }
            })
            .catch((err) => {
                Swal.fire({
                    text: "오류가 발생하였습니다. 다시 시도해주세요",
                    confirmButtonText: "확인",
                });
            });
    }, []);

    useEffect(() => {
        getShopList();
    }, []);

    return {
        pumpList,
        alarmList,
        shopList,
        getPumpStatus,
        getAlarmList,
        downloadExcel,
        checkAlarm,
        syncTime,
        loading,
    };
};

export {useWaterPump};
