import React, {useEffect, useState} from "react";

import {CnSelect} from "~/components/cn-select";
import {httpRequest} from "~/fetch/common/http-request";
import {ShopList} from "~/hooks/use-shop/use-shop.interface";

const useShop = () => {
    const [shop, setShop] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectList, setSelectList] = useState<Array<{value: number; label: string}>>([]);

    useEffect(() => {
        setIsLoading(true);
        const request = httpRequest<null, ShopList>({
            url: "/sector/manager",
        });
        request
            .submit()
            .then((res) => {
                setSelectList([
                    {
                        value: 0,
                        label: "전체",
                    },
                    ...res.data
                        .map((shop) => {
                            return {
                                value: shop.buildingId,
                                label: shop.buildingName,
                            };
                        })
                        .sort((a, b) => a.label.localeCompare(b.label, "ko-KR")),
                ]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const handleReset = () => {
        setShop(0);
    };

    const ShopSelect = () => {
        return (
            <CnSelect
                label={"지점"}
                showSearch
                placeholder="지점"
                onChange={(value) => setShop(value)}
                value={shop}
                loading={isLoading}
                options={selectList}
            />
        );
    };

    return {
        shop,
        setShop,
        reset: handleReset,
        ShopSelect,
        shopList: selectList,
    };
};

export {useShop};
